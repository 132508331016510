<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-layout column>
    <v-card class="mb-4 rounded-xl overflow-hidden" :disabled="loading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">Quotes</div>
        <v-divider class="mx-2" vertical />
        <template>
          <v-switch class="mt-4" v-model="fail" label="NoT Approved"></v-switch>
        </template>
        <v-autocomplete
          v-if="filterPatients == 'patients'"
          class="mx-1"
          :label="'Filter by ' + getpatientN + 's'"
          :items="patients"
          v-model="bodyreq.patientUuid"
          item-text="fullname"
          item-value="uuid"
          style="max-width: 300px"
          prepend-inner-icon="mdi-account-search"
          clearable
          hide-details
          outlined
          @change="filterQuotes"
          @click:clear="resetFilter('patients')"
          dense
        ></v-autocomplete>
        <v-autocomplete
          v-if="filterEmployees == 'employees'"
          class="mx-1"
          label="Filter by Employees"
          :items="workers"
          v-model="bodyreq.employeeUuid"
          item-text="fullname"
          item-value="uuid"
          style="max-width: 300px"
          prepend-inner-icon="mdi-account-search"
          clearable
          @change="filterQuotes"
          @click:clear="resetFilter('employees')"
          hide-details
          outlined
          dense
        ></v-autocomplete>
        <v-layout class="ma-4" v-if="filterRanges == 'custom-select'">
          <v-row class="mt-3" no-gutters>
            <v-col class="mt-3">
              <ma-date-picker v-model="dateFrom" label="From" past />
            </v-col>
            <v-col class="ml-2 mt-3">
              <ma-date-picker v-model="dateTo" label="To" />
            </v-col>
          </v-row>
        </v-layout>
        <v-menu :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="ml-auto"
              :loading="loading"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>mdi-tune</v-icon>
              Filter
            </v-btn>
          </template>

          <v-list width="140" nav dense>
            <v-list-item link @click="filterEmployees = 'employees'">
              <v-list-item-title>Employees</v-list-item-title>
            </v-list-item>
            <v-divider />

            <v-list-item link @click="filterPatients = 'patients'">
              <v-list-item-title>{{ getpatientN + "s" }}</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Type</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link @click="filterType = 'Treatment'">
                <v-list-item-title class="blue--text"
                  >Treatment</v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="filterType = 'Surgery'">
                <v-list-item-title class="green--text"
                  >Surgery</v-list-item-title
                >
              </v-list-item>
            </v-list-group>
            <v-divider />
            <v-list-item link @click="filterRanges = 'custom-select'">
              <v-list-item-title>Custom Select</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item
              v-for="interval in intervals"
              :key="interval.value"
              link
              @click="setIntervar(interval.value)"
            >
              <v-list-item-title>{{ interval.label }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="resetFilter">
              <v-list-item-title class="red--text"
                >Clear Filters</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      {{/* Interval indicator */}}
      <v-layout class="pa-4" column>
        <div class="mr-2 text-capitalize">
          {{ filterRanges.replace("-", " ") }}
        </div>
        <v-layout column class="text-body-1 text--secondary">
          <div>
            From:
            <span class="text-caption">{{ prettyDate(bodyreq.dates[0]) }}</span>
          </div>
          <div>
            To:
            <span class="text-caption">{{ prettyDate(bodyreq.dates[1]) }}</span>
          </div>
        </v-layout>
      </v-layout>

      <v-divider />
      <v-data-table
        :headers="headers"
        :items="quotes"
        :server-items-length="totalitems"
        :options.sync="options"
        :footer-props="footerProps"
        class="elevation-1"
        item-key="id"
        :loading="loading"
        search="search"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ prettyDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.patient`]="{ item }">
          {{ item.patient.fullname }}
        </template>
        <template v-slot:[`item.quotedAmount`]="{ item }">
          {{ "$" + item.quotedAmount }}
        </template>
        <template v-slot:[`item.aproved`]="{ item }">
          <v-icon color="success" v-if="item.aproved">mdi-check-all</v-icon>
          <span class="orange--text" v-else><strong>SENT</strong> </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            {{/* Approve Quote */}}
            <template v-if="!item.aproved">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="openCheck(item)"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>Approve Quote</span>
              </v-tooltip>
              {{/* Resend Quote */}}

              <v-tooltip transition="fade-transition" top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    :color="btnProps.color || 'primary'"
                    icon
                    depressed
                    @click="toResend(item)"
                    v-on="{ ...tooltip }"
                    v-bind="btnProps"
                  >
                    <v-icon> mdi-email-alert </v-icon>
                  </v-btn>
                </template>

                <span>Resend Quote</span>
              </v-tooltip>
            </template>

            {{/* View Quote PDF */}}
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="getPdf(item.id)"
                >
                  <v-icon>mdi-file-pdf</v-icon>
                </v-btn>
              </template>
              <span>View Quote</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialogcheck"
        persistent
        :overlay="false"
        max-width="300px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar dark color="primary"> Approve Quote </v-toolbar>
          <v-card-title primary-title> Are you sure ? </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="closeCheck()"> Cancel </v-btn>
            <v-btn color="primary" @click="approve()"> Approve </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <template v-if="resend.dialog">
        <div class="text-center">
          <v-dialog v-model="resend.dialog" width="500">
            <v-card>
              <v-form ref="form" v-model="resend.formValid">
                <v-card-title class="white--text text-h5 blue lighten-2">
                  Resend Quote To: {{ resend.patientName }}
                </v-card-title>

                <v-card-text>
                  <v-checkbox
                    :label="'Update ' + getpatientN + ' Email'"
                    v-model="resend.checkedit"
                  ></v-checkbox>
                  <v-text-field
                    v-model="resend.emaildata"
                    label="Email"
                    outlined
                    :rules="[resend.rules.required, resend.rules.email]"
                    dense
                    class="mt-2"
                  ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="closeDialog">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    :loading="resend.loading"
                    :disabled="!resend.formValid"
                    text
                    @click="_resend"
                  >
                    Send
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <document-viewer
        v-if="showDocuments"
        v-model="showDocuments"
        :documents="quotepdf"
      />
    </v-card>
  </v-layout>
</template>
<script>
import Vue from "vue";
import { apiBaseUrl } from "@/enviorment";
import rules from "@/components/account/rules";
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import moment from "moment";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import DocumentViewer from "../PdfSign/DocumentViewer.vue";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
export default Vue.extend({
  props: {
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { MaDatePicker, DocumentViewer },
  data() {
    return {
      fail: false,
      filterEmployees: "",
      filterPatients: "",
      filterType: null,
      footerProps: { "items-per-page-options": [50, 100, 500] },
      filterRanges: "this-year",
      options: {},
      expanded: [],
      dateFrom: "",
      dateTo: "",
      quotepdf: [],
      showDocuments: false,
      dialogcheck: false,
      itemAprove: null,

      bodyreq: {
        patientUuid: null,
        employeeUuid: null,
        aproved: true,
        quote_type: null,
        dates: ["", ""],
        range: {
          limit: 100,
          offset: 0,
        },
      },

      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],

      resend: {
        formValid: false,
        rules,
        dialog: false,
        loading: false,
        checkedit: false,
        showErrors: false,
        errorMessage: "",
        emaildata: "",
        patientName: "",
        quoteId: "",
        patientUuid: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    ...mapState("crmConfigurationsModule", ["quotes", "loading"]),
    ...mapState(["totalitems"]),
    /*INTERVALS*/
    intervalToday() {
      const from = moment();
      from.hour(0);
      from.minute(0);
      from.second(0);
      const to = moment();

      return {
        date1: from,
        date2: to,
      };
    },
    headers() {
      return [
        { text: "Id", value: "id", sortable: false },
        { text: "Type", value: "quote_type", sortable: true },
        { text: this.getpatientN, value: "patient" },
        {
          text: "Amount $",
          value: "quotedAmount",
          sortable: true,
          align: "end",
        },
        { text: "Quote date", value: "createdAt" },
        { text: "Status", value: "aproved", align: "center" },
        { text: "", value: "actions", sortable: false, align: "center" },
        { text: "", value: "data-table-expand", sortable: false },
      ];
    },

    intervarYesterDay() {
      const from = moment().subtract(1, "days").hour(0).minute(0).second(0);
      const to = moment().subtract(1, "days").hour(23).minute(59).second(59);

      return {
        date1: from,
        date2: to,
      };
    },
    intervarThisWeek() {
      const from = moment().day(0).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from,
        date2: to,
      };
    },
    intervarLastWeek() {
      const from = moment().subtract(1, "week").hour(0).minute(0).second(0);
      const to = moment()
        .subtract(1, "week")
        .add(6, "days")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from,
        date2: to,
      };
    },
    intervarThisMonth() {
      const from = moment().date(1).hour(0).minute(0).second(0);
      const to = moment().hour(23).minute(59).second(59);

      return {
        date1: from,
        date2: to,
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;
      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return this.intervarThisMonth;
      }

      const from = moment()
        .subtract(1, "month")
        .date(1)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .date(1)
        .subtract(1, "days")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from,
        date2: to,
      };
    },
    intervarThisYear() {
      const from = moment().month(0).date(1).hour(0).minute(0).second(0);
      const to = moment();

      return {
        date1: from,
        date2: to,
      };
    },
    intervarLastYear() {
      const from = moment()
        .subtract(1, "year")
        .month(0)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment()
        .month(0)
        .date(1)
        .subtract(1, "day")
        .hour(23)
        .minute(59)
        .second(59);

      return {
        date1: from,
        date2: to,
      };
    },
  },
  watch: {
    fail(val) {
      this.bodyreq.aproved = !val;
      this.filterQuotes();
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterQuotes();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterQuotes();
      }
    },
    filterType(val) {
      if (val != null && val != undefined) {
        this.bodyreq.quote_type = val;
      }
      this.filterQuotes();
    },
    options(val) {
      if (val != {}) {
        this.filterQuotes();
      }
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListPatientsFilter",
      "actAproveQuote",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmConfigurationsModule", ["actGetQuotes"]),
    ...mapMutations(["mutReqRange"]),
    toResend(item) {
      this.resend.dialog = true;
      this.resend.quoteId = item.id;
      this.resend.emaildata = item.patient.email;
      this.resend.patientName = item.patient.fullname;
      this.resend.patientUuid = item.patient.uuid;
    },
    _resend() {
      this.resend.loading = true;
      if (this.resend.checkedit) {
        getAPI
          .put("/patient/updatePatient/" + this.resend.patientUuid, {
            email: this.resend.emaildata,
          })
          .then(() => {
            notifySuccess(getpatientN + " Email has been updated!");
          })
          .catch((error) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
      getAPI
        .put(`/sprocedures/resendQuoteByEmailToSign`, {
          quoteId: this.resend.quoteId,
          email: this.resend.emaildata,
        })
        .then(() => {
          this.resend.loading = false;
          notifyInfo(this.$t("notification"));
          this.closeDialog();
        })
        .catch((error) => {
          this.resend.loading = false;
          this.closeDialog();
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    closeDialog() {
      this.resend.loading = false;
      this.resend.dialog = false;
      this.resend.showErrors = false;
      this.resend.errorMessage = "";
      this.resend.emaildata = "";
      this.resend.quoteId = "";
      this.resend.checkedit = false;
      this.resend.patientName = "";
      this.resend.patientUuid = "";
      this.filterQuotes();
    },

    setIntervar(interval) {
      this.filterRanges = interval;
      this.filterQuotes();
    },
    filterQuotes() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 50;
      let body = this.bodyreq;
      body.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.mutReqRange(body.range);
      switch (this.filterRanges) {
        case "today":
          body.dates = [this.intervalToday.date1, this.intervalToday.date2];
          break;
        case "yesterday":
          body.dates = [
            this.intervarYesterDay.date1,
            this.intervarYesterDay.date2,
          ];
          break;
        case "this-week":
          body.dates = [
            this.intervarThisWeek.date1,
            this.intervarThisWeek.date2,
          ];
          break;
        case "last-week":
          body.dates = [
            this.intervarLastWeek.date1,
            this.intervarLastWeek.date2,
          ];
          break;
        case "this-month":
          body.dates = [
            this.intervarThisMonth.date1,
            this.intervarThisMonth.date2,
          ];
          break;
        case "last-month":
          body.dates = [
            this.intervarLastMonth.date1,
            this.intervarLastMonth.date2,
          ];
          break;
        case "this-year":
          body.dates = [
            this.intervarThisYear.date1,
            this.intervarThisYear.date2,
          ];
          break;
        case "last-year":
          body.dates = [
            this.intervarLastYear.date1,
            this.intervarLastYear.date2,
          ];
          break;
        case "custom-select":
          body.dates = [
            moment(this.dateFrom).startOf("day"),
            moment(this.dateTo).endOf("day"),
          ];
          break;
      }

      body = this.cleanNull(body);

      this.actGetQuotes(body);
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    resetFilter(value) {
      switch (value) {
        case "custom-select":
          this.filterRanges = "this-year";

          this.filterQuotes();
          break;
        case "patients":
          this.filterPatients = "";
          break;
        case "employees":
          this.filterEmployees = "";
          break;

        default:
          this.filterPatients = "";
          this.filterEmployees = "";

          this.filterType = null;
          this.filterRanges = "this-year";
          this.fail = false;
          this.bodyreq = {
            aproved: true,
            patientUuid: null,
            employeeUuid: null,
            dates: ["", ""],
          };
          this.filterQuotes();
          break;
      }
    },
    async getPdf(id) {
      const pdf = {
        title: "Quote PDF",
        url: `${apiBaseUrl}/sprocedures/getQuotePdf/${id}`,
      };
      this.quotepdf[0] = pdf;
      this.showDocuments = true;
    },

    openCheck(item) {
      this.dialogcheck = true;
      this.itemAprove = item;
    },
    async approve() {
      await this.actAproveQuote(this.itemAprove.id);
      this.filterQuotes();
      this.closeCheck();
    },
    closeCheck() {
      this.dialogcheck = false;
      this.itemAprove = null;
    },
  },
  mounted() {
    this.actListPatientsFilter();
    this.actGetEmployees();
    this.setIntervar(this.filterRanges);
  },
});
</script>
<style lang=""></style>
<i18n>
{
  "en": {
    "notification": "Quote have been sended"
  },
  "es": {
    "notification": "El Quote ha sido enviado"
  }
}
</i18n>
